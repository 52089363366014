import './App.css';
import Nav from './components/shared/Nav';
import Footer from './components/shared/Footer';
import ServiceProviders from './components/ServiceProviders';
import Careers from './components/Careers';
import About from './components/About';
import Partners from './components/Partners';
import Home from './components/Home';
import Homeowners from './components/Homeowners';
// import Contact from './components/Contact';
import Privacy from './components/Privacy';
import { useLocation, Route, Routes } from 'react-router-dom';
import ClaimsPage from './components/claims/ClaimsPage'

function App() {
  const isHomeowners = (useLocation().pathname === '/homeowners')
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service-providers" element={<ServiceProviders />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/homeowners" element={<Homeowners />} />
        <Route path="/claims" element={<ClaimsPage />} />
        {/* <Route path="/contact" element={<Contact/>} /> */}
        <Route path="/privacy" element={<Privacy/>} />
      </Routes>
      <Footer isHomeowners={isHomeowners} />
    </div>
  );
}

export default App;
