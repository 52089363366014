import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import DOMPurify from 'dompurify';
import { TextField } from './TextField';
import { Textarea } from './Textarea';
import { SelectField } from './SelectField';
import { RadioField } from './RadioField';
import { PhoneField } from './PhoneField';
import { Button } from './Button';

/**
 * Extracts the organization ID from a Salesforce URL.
 * @param {string} url - The Salesforce URL containing the orgId parameter.
 * @returns {string} - The extracted orgId or default value.
 */
const extractOrgIdFromUrl = (url) => {
  if (!url) return '00DO900000CFCEr';
  try {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    return params.get('orgId') || params.get('orgid') || '00DO900000CFCEr';
  } catch (error) {
    console.warn('Failed to parse Salesforce URL:', error);
    return '00DO900000CFCEr';
  }
};

/**
 * Formats a date string from yyyy-mm-dd to mm/dd/yyyy
 * @param {string} dateString - The date string in yyyy-mm-dd format
 * @returns {string} - The formatted date string in mm/dd/yyyy format
 */
const formatDateToMMDDYYYY = (dateString) => {
  if (!dateString) return '';

  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
};

const recaptchaSiteKey = "6LfAc-kqAAAAAF2qd5UcFTmaWvzLJG2_xXVAnjxQ";
const environment = process.env.REACT_APP_ENVIRONMENT

const environmentUrls = {
  development: "https://oncourse--qa1.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DcZ000000twJW",
  staging: "https://oncourse--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DO900000CFCEr",
  production: "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DDn00000C17ZJ",
}

const salesforceWebToCaseUrl = environmentUrls[environment];

const environmentFields = {
  // Real QA Environment Fields
  development: {
    dateOfIssue: "00NPQ000000Pp3C",
    firstName: "00NcZ000001SLM3",
    lastName: "00NcZ000001SLM4",
    street: "00NcZ000001SLM8",
    aptUnit: "00NcZ000001SLM1",
    city: "00NcZ000001SLM2",
    state: "00NcZ000001SLM5",
    zipCode: "00NcZ000001SLM9",
    plumbingIssue: "00NcZ000001SLLv",
    sewerIssue: "00NcZ000001SLLw",
    waterIssue: "00NcZ000001SLM0",
    whoWillBeHome: "00NcZ000001SLM7",
    havePets: "00NcZ000001SLLx",
    entrance: "00NcZ000001SLLz",
    notification: "00NcZ000001SLLy",
    gateCode: "00NcZ000001SLM6",
    planType: "00NcZ000001SLLt",
    emergencyQuestion: "00NcZ000001SLLu",
    recordType: "012PQ000000pRHX"
  },

  // Dummy UAT Environment Fields
  staging: {
    dateOfIssue: "00NPQ000000Pp3C",
    firstName: "00NO900000EaAlm",
    lastName: "00NO900000EaAln",
    street: "00NO900000EaAlr",
    aptUnit: "00NO900000EaAlk",
    city: "00NO900000EaAll",
    state: "00NO900000EaAlo",
    zipCode: "00NO900000EaAls",
    plumbingIssue: "00NO900000EaAlb",
    sewerIssue: "00NO900000EaAlf",
    waterIssue: "00NO900000EaAlj",
    whoWillBeHome: "00NO900000EaAlq",
    havePets: "00NO900000EaAlg",
    entrance: "00NO900000EaAli",
    notification: "00NO900000EaAlh",
    gateCode: "00NO900000EaAlp",
    planType: "00NO900000EaAlZ",
    emergencyQuestion: "00NO900000EaAla",
    recordType: "012PQ000000pRHX"
  },

  // Dummy Production Environment Fields
  production: {
    dateOfIssue: "00NPQ000000Pp3C",
    firstName: "00NPQ000001sLtW",
    lastName: "00NPQ000001sLtX",
    street: "00NPQ000001sLtb",
    aptUnit: "00NPQ000001sLtU",
    city: "00NPQ000001sLtV",
    state: "00NPQ000001sLtY",
    zipCode: "00NPQ000001sLtc",
    plumbingIssue: "00NPQ000001sLtL",
    sewerIssue: "00NPQ000001sLtP",
    waterIssue: "00NPQ000001sLtT",
    whoWillBeHome: "00NPQ000001sLta",
    havePets: "00NPQ000001sLtQ",
    entrance: "00NPQ000001sLtS",
    notification: "00NPQ000001sLtR",
    gateCode: "00NPQ000001sLtZ",
    planType: "00NPQ000001sLtJ",
    emergencyQuestion: "00NPQ000001sLtK",
    recordType: "012PQ000000pRHX"
  }
};

const captchaKeynames = {
  development: "development",
  staging: "staging",
  production: "production"
};

// Function to get fields for current environment
const getFields = () => {
  return environmentFields[environment]
};

// Dynamically create the Zod schema based on environment
const createSupportCaseSchema = () => {
  const fields = getFields();

  // Helper function to check for HTML tags and JavaScript code
  const containsHtmlOrScript = (text) => {
    if (!text) return false;
    const sanitized = DOMPurify.sanitize(String(text), {
      ALLOWED_TAGS: [], // Allow no HTML tags
      ALLOWED_ATTR: []  // Allow no attributes
    });
    return sanitized !== String(text);
  };

  return z.object({
    [fields.firstName]: z.string()
      .min(1, "First Name is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "First Name cannot contain HTML or special characters" }
      ),
    [fields.lastName]: z.string()
      .min(1, "Last Name is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Last Name cannot contain HTML or special characters" }
      ),
    [fields.street]: z.string()
      .min(1, "Street is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Street cannot contain HTML or special characters" }
      ),
    [fields.aptUnit]: z.string()
      .optional()
      .refine(
        (value) => !value || !containsHtmlOrScript(value),
        { message: "Apt/Unit cannot contain HTML or special characters" }
      ),
    [fields.city]: z.string()
      .min(1, "City is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "City cannot contain HTML or special characters" }
      ),
    [fields.state]: z.string()
      .min(1, "State is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "State cannot contain HTML or special characters" }
      ),
    [fields.zipCode]: z.string()
      .min(1, "Zip Code is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Zip Code cannot contain HTML or special characters" }
      ),
    email: z.string()
      .min(1, "Email is required")
      .email("Invalid email address")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Email cannot contain HTML or special characters" }
      ),
    description: z.string()
      .min(1, "Description is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Description cannot contain HTML or special characters" }
      )
      .refine(
        (text) => {
          const wordCount = text.trim().split(/\s+/).length;
          return wordCount <= 1200;
        },
        { message: "Description must be 1200 words or less" }
      ),
    phone: z.string()
      .min(1, "Phone is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "Phone cannot contain HTML or special characters" }
      ),
    dateInputValue: z.string().min(1, "Date of Issue is required"),
    planTypeSelect: z.string().min(1, "Plan Type is required"),
    [fields.emergencyQuestion]: z.string().optional(),
    [fields.plumbingIssue]: z.string().optional(),
    [fields.sewerIssue]: z.string().optional(),
    [fields.waterIssue]: z.string().optional(),
    [fields.whoWillBeHome]: z.string()
      .min(1, "Who will be home is required")
      .refine(
        (value) => !containsHtmlOrScript(value),
        { message: "This field cannot contain HTML or special characters" }
      ),
    [fields.havePets]: z.string().min(1, "Please select if you have pets"),
    [fields.entrance]: z.string().min(1, "Which entrance is required"),
    [fields.notification]: z.string().min(1, "Notification method is required"),
    [fields.gateCode]: z.string()
      .optional()
      .refine(
        (value) => !value || !containsHtmlOrScript(value),
        { message: "Gate code cannot contain HTML or special characters" }
      ),
  });
};

// ------------------ Styled Components ------------------
const FormContainer = styled.form`
    width: 100%;
    max-width: 1140px;
    text-align: left;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    color: #212121;
    position: relative;
    margin: 0 auto;
    font-family: "Manrope", arial;
    overflow: hidden;
`;

const FormHeader = styled.div`
    width: 100%;
    background-color: #F26722;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 24px;
    box-sizing: border-box;
`;

const FormInner = styled.div`
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
`;

const FormRow = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }
`;

const FormColumn = styled.div`
    flex: 1;
`;

const HiddenSection = styled.div`
    margin-bottom: 24px;
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    margin-top: 24px;
`;

const RecaptchaContainer = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
`;

const ErrorMessage = styled.p`
    color: #FF0000;
    font-size: 14px;
    text-align: right;
    margin-top: 0;
    margin-bottom: 13px;
`;

const SubmitButton = styled(Button)`
    background-color: #0364D8;
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

const CancelButton = styled(Button)`
    padding: 12px 24px;
    background: none;
    border: 0;
    color: #006699;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: white;
    }
`;


// ------------------ Options Arrays ------------------
const planTypeOptions = [
  { value: 'In-home Plumbing', label: 'In-home Plumbing' },
  { value: 'Sewer Line', label: 'Sewer Line' },
  { value: 'Water Line', label: 'Water Line' },
  { value: 'Other', label: 'Other' },
];

const plumbingIssueOptions = [
  { value: 'Leak or break in the internal supply system (SS)', label: 'Leak or break in the internal supply system (SS)' },
  { value: 'Leak or break in the drainage system (DS) without a clog or blockage', label: 'Leak or break in the drainage system (DS) without a clog or blockage' },
  { value: 'Clog or blockage in the drainage system (DS)', label: 'Clog or blockage in the drainage system (DS)' },
  { value: 'Clog or blockage in the supply system (SS) instead of the drainage system (DS)', label: 'Clog or blockage in the supply system (SS) instead of the drainage system (DS)' },
  { value: 'Related to a fixture (Toilet, faucet, etc.)', label: 'Related to a fixture (Toilet, faucet, etc.)' },
  { value: 'Other', label: 'Other' },
];

const sewerIssueOptions = [
  { value: 'Clog or blockage in the lateral sewer service line', label: 'Clog or blockage in the lateral sewer service line' },
  { value: 'Issue involves the septic tank or its components (e.g., pump, grinder)', label: 'Issue involves the septic tank or its components (e.g., pump, grinder)' },
  { value: 'Other', label: 'Other' },
];

const waterIssueOptions = [
  { value: 'Leak or break in the main water service line', label: 'Leak or break in the main water service line' },
  { value: 'Leaking main shut-off valve', label: 'Leaking main shut-off valve' },
  { value: 'Clog or blockage in the water line', label: 'Clog or blockage in the water line' },
  { value: 'Issue involves fire suppression systems, sprinklers, irrigation, or backflow preventers', label: 'Issue involves fire suppression systems, sprinklers, irrigation, or backflow preventers' },
  { value: 'Other', label: 'Other' },
];

const entranceOptions = [
  { value: 'Front', label: 'Front' },
  { value: 'Back', label: 'Back' },
  { value: 'Side', label: 'Side' },
];

const notificationOptions = [
  { value: 'Ring', label: 'Ring' },
  { value: 'Knock', label: 'Knock' },
  { value: 'Call', label: 'Call' },
];

const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

// ------------------ SupportCaseForm Component ------------------
export function SupportCaseForm() {
  const recaptchaRef = useRef(null);
  const fields = getFields();
  const supportCaseSchema = createSupportCaseSchema();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(supportCaseSchema),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [retURL, setRetURL] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [captchaSettings, setCaptchaSettings] = useState({
    keyname: captchaKeynames[environment] || environment,
    fallback: "false",
    orgId: extractOrgIdFromUrl(salesforceWebToCaseUrl),
    ts: ""
  });

  useEffect(() => {
    setRetURL(window.location.href + '?success=true');
  }, []);

  // Use watch to determine the selected plan type
  const selectedPlanType = watch("planTypeSelect");
  const dateInputValue = watch("dateInputValue");

  // Update the formatted date when the input changes
  useEffect(() => {
    if (dateInputValue) {
      const formatted = formatDateToMMDDYYYY(dateInputValue);
      setFormattedDate(formatted);
    } else {
      setFormattedDate("");
    }
  }, [dateInputValue]);


  useEffect(() => {
    if (!recaptchaValue) {
      const intervalId = setInterval(() => {
        setCaptchaSettings(prev => ({
          ...prev,
          ts: new Date().getTime().toString()
        }));
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [recaptchaValue]);

  const onSubmit = (data) => {
    if (recaptchaSiteKey && !recaptchaValue) {
      setRecaptchaError(true);
      return;
    }
    setIsSubmitting(true);
    const dateInputField = document.querySelector('input[name="dateInputValue"]');
    if (dateInputField) {
      const originalName = dateInputField.getAttribute('name');
      dateInputField.removeAttribute('name');
      document.getElementById("claimform").submit();
      setTimeout(() => {
        dateInputField.setAttribute('name', originalName);
      }, 500);
    } else {
      document.getElementById("claimform").submit();
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setRecaptchaError(false);
  };

  const handleRecaptchaExpired = () => {
    setRecaptchaValue(null);
  };

  const handleCancel = () => {
    reset();
    setIsSubmitting(false);
    setRecaptchaValue(null);
    setRecaptchaError(false);
    setFormattedDate("");
  };

  return (
    <FormContainer
      id="claimform"
      action={salesforceWebToCaseUrl}
      method="POST"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <FormHeader>Claim Submission Form</FormHeader>
      <FormInner>
        {/* Hidden fields */}
        <input type="hidden" name="orgid" value={extractOrgIdFromUrl(salesforceWebToCaseUrl)} />
        <input type="hidden" name="retURL" value={retURL} />
        <input type="hidden" name="status" value="New" />
        <input
          type="hidden"
          name="captcha_settings"
          value={JSON.stringify(captchaSettings)}
        />
        <input type="hidden" name="recordType" value={fields.recordType} />
        {/*<input type="hidden" name="debug" value="1"/>*/}
        {/*<input type="hidden" name="debugEmail" value="pooja.sharma@oncoursehome.com"/>*/}
        <input
          type="hidden"
          name={fields.planType}
          value={selectedPlanType || ""}
          id="plan"
        />
        <input
          type="hidden"
          name="subject"
          id="subject"
          value={selectedPlanType ? `${selectedPlanType} Claim` : 'Other Claim'}
        />
        {/* Hidden formatted date field for submission */}
        <input
          type="hidden"
          name={fields.dateOfIssue}
          value={formattedDate}
        />

        {/* Basic Fields */}
        <FormRow>
          <FormColumn>
            <TextField
              label="First Name"
              id={fields.firstName}
              placeholder="Enter First Name"
              {...register(fields.firstName)}
              error={errors[fields.firstName]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <TextField
              label="Last Name"
              id={fields.lastName}
              placeholder="Enter Last Name"
              {...register(fields.lastName)}
              error={errors[fields.lastName]?.message}
              required
            />
          </FormColumn>
        </FormRow>

        {/* Street / Apt */}
        <FormRow>
          <FormColumn>
            <TextField
              label="Street"
              id={fields.street}
              placeholder="Enter Street Address"
              {...register(fields.street)}
              error={errors[fields.street]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <TextField
              label="Apt., Unit, PO Box, etc."
              id={fields.aptUnit}
              placeholder="Optional"
              {...register(fields.aptUnit)}
            />
          </FormColumn>
        </FormRow>

        {/* City / State */}
        <FormRow>
          <FormColumn>
            <TextField
              label="City:"
              id={fields.city}
              placeholder="Enter City"
              {...register(fields.city)}
              error={errors[fields.city]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <TextField
              label="State:"
              id={fields.state}
              placeholder="Enter State"
              {...register(fields.state)}
              error={errors[fields.state]?.message}
              required
            />
          </FormColumn>
        </FormRow>

        {/* Zip / Email */}
        <FormRow>
          <FormColumn>
            <TextField
              label="Zip/Postal Code:"
              id={fields.zipCode}
              placeholder="Enter Zip Code"
              {...register(fields.zipCode)}
              error={errors[fields.zipCode]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <TextField
              label="Email:"
              id="email"
              type="email"
              placeholder="Enter Email Address"
              {...register("email")}
              error={errors.email?.message}
              required
            />
          </FormColumn>
        </FormRow>

        {/* Description / Phone */}
        <FormRow>
          <FormColumn>
            <Textarea
              label="Describe the details of your claim"
              id="description"
              placeholder="Describe in 1200 words or less..."
              {...register("description")}
              error={errors.description?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <PhoneField
              label="Phone"
              id="phone"
              placeholder="(123) 456-7890"
              {...register("phone")}
              error={errors.phone?.message}
              required
            />
          </FormColumn>
        </FormRow>

        {/* Date / Plan Type */}
        <FormRow>
          <FormColumn>
            <TextField
              label="Date of Issue:"
              id="dateInputValue"
              type="date"
              placeholder="mm/dd/yyyy"
              {...register("dateInputValue")}
              error={errors.dateInputValue?.message}
              max={new Date().toISOString().split('T')[0]}
              required
            />
          </FormColumn>
          <FormColumn>
            <Controller
              name="planTypeSelect"
              control={control}
              rules={{ required: "Plan Type is required" }}
              render={({ field, fieldState: { error } }) => (
                <SelectField
                  label="Plan Type:"
                  id="planTypeSelect"
                  options={planTypeOptions}
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={error?.message}
                  required
                />
              )}
            />
          </FormColumn>
        </FormRow>

        {/* Conditionally Rendered Sections */}
        {selectedPlanType === 'In-home Plumbing' && (
          <HiddenSection id="fields_plumbing">
            <FormRow>
              <FormColumn>
                <Controller
                  name={fields.emergencyQuestion}
                  control={control}
                  rules={{ required: "Please select an option" }}
                  render={({ field, fieldState: { error } }) => (
                    <RadioField
                      label="Do you have access to any water without a significant leak and is at least one tub/sink and toilet functional?"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      options={yesNoOptions}
                      required
                      error={error?.message}
                    />
                  )}
                />
              </FormColumn>
              <FormColumn>
                <SelectField
                  label="What is the issue with your in-home plumbing?"
                  id={fields.plumbingIssue}
                  options={plumbingIssueOptions}
                  {...register(fields.plumbingIssue)}
                  error={errors[fields.plumbingIssue]?.message}
                  required
                />
              </FormColumn>
            </FormRow>
          </HiddenSection>
        )}

        {selectedPlanType === 'Sewer Line' && (
          <HiddenSection id="fields_sewer">
            <FormRow>
              <FormColumn>
                <Controller
                  name={fields.emergencyQuestion}
                  control={control}
                  rules={{ required: "Please select an option" }}
                  render={({ field, fieldState: { error } }) => (
                    <RadioField
                      label="Are you able to use at least one of your toilets, showers, sinks, or other fixtures without significant hardship?"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      options={yesNoOptions}
                      required
                      error={error?.message}
                    />
                  )}
                />
              </FormColumn>
              <FormColumn>
                <SelectField
                  label="What is the issue with your sewer line?"
                  id={fields.sewerIssue}
                  options={sewerIssueOptions}
                  {...register(fields.sewerIssue)}
                  error={errors[fields.sewerIssue]?.message}
                  required
                />
              </FormColumn>
            </FormRow>
          </HiddenSection>
        )}

        {selectedPlanType === 'Water Line' && (
          <HiddenSection id="fields_water">
            <FormRow>
              <FormColumn>
                <Controller
                  name={fields.emergencyQuestion}
                  control={control}
                  rules={{ required: "Please select an option" }}
                  render={({ field, fieldState: { error } }) => (
                    <RadioField
                      label="Do you have access to any water without a significant leak?"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                      options={yesNoOptions}
                      required
                      error={error?.message}
                    />
                  )}
                />
              </FormColumn>
              <FormColumn>
                <SelectField
                  label="What is the issue with your water line?"
                  id={fields.waterIssue}
                  options={waterIssueOptions}
                  {...register(fields.waterIssue)}
                  error={errors[fields.waterIssue]?.message}
                  required
                />
              </FormColumn>
            </FormRow>
          </HiddenSection>
        )}

        {/* Who will be home / Pets */}
        <FormRow>
          <FormColumn>
            <TextField
              label="Who will be home?"
              id={fields.whoWillBeHome}
              maxLength="255"
              placeholder="Enter First & Last Name"
              {...register(fields.whoWillBeHome)}
              error={errors[fields.whoWillBeHome]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <Controller
              name={fields.havePets}
              control={control}
              rules={{ required: "Please select if you have pets" }}
              render={({ field, fieldState: { error } }) => (
                <RadioField
                  label="Do you have pets?"
                  name={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  options={yesNoOptions}
                  required
                  error={error?.message}
                />
              )}
            />
          </FormColumn>
        </FormRow>

        {/* Entrance / Notification */}
        <FormRow>
          <FormColumn>
            <SelectField
              label="Which entrance?"
              id={fields.entrance}
              options={entranceOptions}
              {...register(fields.entrance)}
              error={errors[fields.entrance]?.message}
              required
            />
          </FormColumn>
          <FormColumn>
            <SelectField
              label="Ring doorbell/knock/call?"
              id={fields.notification}
              options={notificationOptions}
              {...register(fields.notification)}
              error={errors[fields.notification]?.message}
              required
            />
          </FormColumn>
        </FormRow>

        {/* Gate code / reCAPTCHA */}
        <FormRow>
          <FormColumn>
            <TextField
              label="Gated community? If yes, please enter gate code below"
              id={fields.gateCode}
              maxLength="255"
              placeholder="Enter gate code if applicable"
              {...register(fields.gateCode)}
            />
          </FormColumn>
          <FormColumn>
            {recaptchaSiteKey && (
              <RecaptchaContainer>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaChange}
                  onExpired={handleRecaptchaExpired}
                />
              </RecaptchaContainer>
            )}
          </FormColumn>
        </FormRow>

        <FormActions>
          {recaptchaError && (
            <ErrorMessage>Please complete the reCAPTCHA verification.</ErrorMessage>
          )}
          <CancelButton type="button" onClick={handleCancel}>
            Cancel
          </CancelButton>
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </SubmitButton>
        </FormActions>
      </FormInner>
    </FormContainer>
  );
}

export default SupportCaseForm;
