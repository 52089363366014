import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const TextFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    font-family: 'Manrope', arial;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #181815;
`;

const RequiredIndicator = styled.span`
    color: red;
    margin-left: 4px;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 13px 14px;
    border: 1px solid #D3D4C5;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #ffffff;
    font-family: 'Manrope', arial;

    &::placeholder {
        color: #D3D4C5;
    }

    &:focus {
        outline: none;
        border-color: #0364D8;
    }

    ${props => props.$hasError && css`
        border: 1.5px solid red !important;
        border-radius: 5px;
    `}
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
`;

const HelperText = styled.p`
    color: ${props => props.$hasError ? 'red' : '#6B6B6B'};
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
`;

export const TextField = forwardRef((props, ref) => {
  const {
    label,
    required = false,
    error,
    helperText,
    className,
    placeholder,
    onChange,
    onBlur,
    ...rest
  } = props;

  const hasError = !!error;
  const showHelperText = helperText && !error;

  // Enhanced change handler to check validation
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  // Enhanced blur handler to validate on blur
  const handleBlur = (e) => {
    // Validate field on blur if it's required
    if (required && e.target.value.trim() === '') {
      // If there's a custom onBlur handler, call it
      if (onBlur) {
        onBlur({
          ...e,
          target: {
            ...e.target,
            validationMessage: 'This field is required'
          }
        });
      }
    } else if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <TextFieldContainer className={className}>
      {label && (
        <Label>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </Label>
      )}
      <StyledInput
        ref={ref}
        $hasError={hasError}
        required={required}
        placeholder={placeholder}
        aria-invalid={hasError}
        aria-describedby={helperText || error ? `${label}-helper` : undefined}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
      />
      {error && <ErrorMessage id={`${label}-error`}>{error}</ErrorMessage>}
      {showHelperText && <HelperText id={`${label}-helper`}>{helperText}</HelperText>}
    </TextFieldContainer>
  );
});

TextField.displayName = 'TextField';
