import React, { forwardRef } from 'react';
import styled from 'styled-components';

const RadioFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    font-family: 'Manrope', arial;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #181815;
`;

const RequiredIndicator = styled.span`
    color: red;
    margin-left: 4px;
`;

const RadioOptionContainer = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 8px;
`;

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Manrope', arial;
  font-size: 16px;
  font-weight: 600;
  color: #181815;
`;

const StyledRadioInput = styled.input`
  /* Hide default radio button */
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
  
  /* Create custom radio button */
  width: 20px;
  height: 20px;
  border: 2px solid #D3D4C5;
  border-radius: 50%;
  margin-right: 12px;
  display: grid;
  place-content: center;
  position: relative;
  top: -1px;
  
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s ease-in-out;
    background-color: #0364D8;
  }
  
  &:checked {
    border-color: #0364D8;
    
    &::before {
      transform: scale(1);
    }
  }
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    border-color: #0364D8;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
`;

/**
 * Radio group component with label
 */
export const RadioField = forwardRef(
  (
    {
      label,
      name,
      options = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
      ],
      onChange,
      value,
      required = false,
      error,
      className = '',
      horizontal = true,
      ...props
    },
    ref
  ) => {
    return (
      <RadioFieldContainer className={className}>
        {label && (
          <Label>
            {label}
            {required && <RequiredIndicator>*</RequiredIndicator>}
          </Label>
        )}
        <RadioOptionContainer
          style={{ flexDirection: horizontal ? 'row' : 'column' }}
        >
          {options.map((option) => (
            <RadioWrapper key={`${name}_${option.value}`}>
              <StyledRadioInput
                type="radio"
                id={`${name}_${option.value}`}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={onChange}
                required={required}
                ref={ref}
                {...props}
              />
              {option.label}
            </RadioWrapper>
          ))}
        </RadioOptionContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </RadioFieldContainer>
    );
  }
);

RadioField.displayName = 'RadioField';
