import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { SimpleMessage } from "./SimpleMessage";
import { SupportCaseForm } from "./SupportCaseForm";

const DisclaimerText = styled.p`
  font-size: 1.4rem;
  text-align: center;
  color: var(--text-medium);
  margin: 0;
  line-height: 1.5;
`;

const PageContainer = styled.main`
  margin: 0 auto;
  max-width: 985px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px;
`;

export default function ClaimsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const showSuccessMessage = searchParams.get("success") === "true";

  return (
    <PageContainer>
      {showSuccessMessage && (
        <SimpleMessage
          message='Your claim has been successfully submitted. You will receive an email confirmation shortly.'
          type='success'
        />
      )}
      <SupportCaseForm />

      <DisclaimerText>
        By providing my contact information above, I consent to receiving
        updates from Oncourse Home Solutions regarding my claim.
      </DisclaimerText>
    </PageContainer>
  );
}
