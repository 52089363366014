import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    font-family: 'Manrope', arial;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    color: #181815;
`;

const RequiredIndicator = styled.span`
    color: red;
    margin-left: 4px;
`;

const SelectWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 13px 14px;
  border: 1px solid #D3D4C5;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #ffffff;
  font-family: 'Manrope', arial;
  appearance: none;
  padding-right: 40px; /* Make room for the custom dropdown icon */
  cursor: pointer;
  color: #181815;
  
  &:focus {
    outline: none;
    border-color: #0364D8;
  }
  
  &::placeholder {
    color: #D3D4C5;
  }
  
  &:disabled {
    background-color: #F5F5F5;
    border-color: #E0E0E0;
    color: #9E9E9E;
  }
  
  ${props => props.$hasError && css`
    border: 1.5px solid red !important;
    border-radius: 5px;
  `}
`;

// Custom dropdown indicator
const DropdownIndicator = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181815;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
`;

export const SelectField = forwardRef((props, ref) => {
  const {
    label,
    required = false,
    error,
    options,
    className,
    placeholder = "-Select-",
    ...rest
  } = props;

  return (
    <SelectContainer className={className}>
      {label && (
        <Label>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </Label>
      )}
      <SelectWrapper>
        <StyledSelect
          ref={ref}
          $hasError={!!error}
          required={required}
          {...rest}
        >
          <option value="">{placeholder}</option>
          {options && options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
        <DropdownIndicator>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6L8 10L12 6" stroke="#181815" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </DropdownIndicator>
      </SelectWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </SelectContainer>
  );
});

SelectField.displayName = 'SelectField';
