import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
  font-family: 'Manrope', arial;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #181815;
`;

const RequiredIndicator = styled.span`
    color: red;
    margin-left: 4px;
`;

const StyledTextarea = styled.textarea`
    width: 100%;
    padding: 13px 14px;
    border: 1px solid #D3D4C5;
    border-radius: 8px;
    box-sizing: border-box;
    height: 150px;
    background-color: #ffffff;
    font-family: 'Manrope', arial;
    resize: vertical;

    &::placeholder {
        color: #D3D4C5;
    }

    &:focus {
        outline: none;
        border-color: #0364D8;
    }

    ${props => props.$hasError && css`
    border: 1.5px solid red !important;
    border-radius: 5px;
  `}
`;

const ErrorMessage = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 0;
`;

const HelperText = styled.p`
    font-size: 14px;
    color: #666;
    margin-top: 4px;
    margin-bottom: 0;
`;

export const Textarea = forwardRef((props, ref) => {
  const {
    label,
    required = false,
    error,
    helperText,
    className,
    placeholder = "Describe in 1200 words or less...",
    ...rest
  } = props;

  return (
    <TextareaContainer className={className}>
      {label && (
        <Label>
          {label}
          {required && <RequiredIndicator>*</RequiredIndicator>}
        </Label>
      )}
      <StyledTextarea
        ref={ref}
        $hasError={!!error}
        required={required}
        placeholder={placeholder}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {helperText && !error && <HelperText>{helperText}</HelperText>}
    </TextareaContainer>
  );
});

Textarea.displayName = 'Textarea';
