import React from 'react';
import { TextField } from './TextField'

const PhoneField = React.forwardRef((props, forwardedRef) => {
  const { onChange, ...rest } = props;

  const handleChange = (event) => {
    const inputElement = event.currentTarget;
    inputElement.value = inputElement.value.replace(/\D/g, '').slice(0, 10);

    if (onChange) {
      onChange(event);
    }
  };

  return <TextField {...rest} ref={forwardedRef} type='text' onChange={handleChange} />;
});

PhoneField.displayName = 'PhoneField';

export { PhoneField };
