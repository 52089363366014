import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaXmark } from 'react-icons/fa6';
import { PiCheckCircleFill, PiWarningCircleFill } from 'react-icons/pi';


const colors = {
  error: {
    background: 'rgba(211, 47, 47, 0.1)', // Light red background
    text: 'var(--error-red)'              // Using error-red variable
  },
  success: {
    background: 'var(--green-light)',     // Light green background
    text: 'var(--green)'                  // Using green variable
  },
  warning: {
    background: 'var(--yellow-light)',    // Light yellow background
    text: 'var(--support-purple)'         // Using support-purple for contrast
  },
  info: {
    background: 'var(--blue-light)',      // Light blue background
    text: 'var(--oncourse-blue)'          // Using oncourse-blue variable
  }
};

// Styled container for the message
const MessageContainer = styled.div`
    border-radius: 0.8rem; 
    padding: 1.2rem; 
    font-size: 1.4rem; 
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    background-color: ${props => colors[props.$type]?.background || colors.info.background};
    color: ${props => colors[props.$type]?.text || colors.info.text};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* subtle shadow */
`;

// Styled flex container for the message content
const MessageContent = styled.div`
    display: flex;
    gap: 0.8rem; /* slightly larger gap */
    ${props => props.$inline ? '' : 'flex-direction: column;'}

    @media (min-width: 768px) {
    align-items: ${props => props.$inline ? 'center' : 'flex-start'};
}
`;

// Styled top row for the message with icon and close button
const MessageTopRow = styled.div`
    display: flex;
    gap: 0.8rem; /* slightly larger gap */
    width: 100%;
    align-items: center;
`;

// Styled icon container
const IconContainer = styled.div`
    display: flex;
    align-items: center;
`;

// Styled message text
const MessageText = styled.span`
    font-weight: 600; /* semi-bold for better readability */
`;

// Styled close button container
const CloseContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
`;

// Styled close button
const CloseButton = styled(FaXmark)`
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 1;
    }
`;

// Styled children container
const ChildrenContainer = styled.div`
    font-size: 1.3rem; /* slightly smaller than main text */
    font-weight: 400; /* regular weight for body text */
`;

/**
 * SimpleMessage component displays different types of notifications
 * @param {Object} props - Component props
 * @param {string|React.ReactNode} props.message - Message to display
 * @param {string} props.type - Type of message (error, success, warning, info)
 * @param {boolean} [props.closeIcon=true] - Whether to show close icon
 * @param {React.ReactNode} [props.children] - Additional content
 * @param {number} [props.durationSeconds=0] - Duration in seconds (0 for permanent)
 * @param {string} [props.className=''] - Additional CSS classes
 * @param {Function} [props.onClose] - Callback when close button is clicked
 * @param {boolean} [props.inline] - Whether to show children inline with message
 */
export const SimpleMessage = ({
  message,
  type,
  closeIcon = true,
  children,
  durationSeconds = 0,
  className = '',
  onClose,
  inline
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (message) {
      setShow(true);
    }

    // if the duration = 0 then show the message permanently
    if (durationSeconds !== 0 && !isNaN(durationSeconds)) {
      setTimeout(() => {
        setShow(false);
      }, durationSeconds * 1000);
    }
  }, [message, durationSeconds]);

  if (!show) return null;

  const handleClose = () => {
    setShow(false);

    if (onClose) {
      onClose();
    }
  };

  // Use different icons based on message type to better match OnCourse design
  const getIcon = () => {
    switch(type) {
      case 'success':
        return <PiCheckCircleFill size={20} />;
      case 'error':
        return <PiWarningCircleFill size={20} />;
      case 'warning':
        return <PiWarningCircleFill size={20} />;
      default:
        return <PiCheckCircleFill size={20} />; // Info uses check icon
    }
  };

  return (
    <MessageContainer $type={type} className={className}>
      <MessageContent $inline={inline}>
        <MessageTopRow>
          <IconContainer>
            {getIcon()}
          </IconContainer>
          <MessageText>{message}</MessageText>
          {inline && children && (
            <ChildrenContainer $inline={inline}>{children}</ChildrenContainer>
          )}
          {closeIcon && (
            <CloseContainer>
              <CloseButton onClick={handleClose} />
            </CloseContainer>
          )}
        </MessageTopRow>
        {!inline && children && (
          <ChildrenContainer $inline={inline}>{children}</ChildrenContainer>
        )}
      </MessageContent>
    </MessageContainer>
  );
};

export default SimpleMessage;
